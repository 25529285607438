<template>
  <UiPopup
    :model-value="modelValue"
    title="Create deal"
    primary-button-text="Create deal"
    secondary-button-text="Create new lead"
    size="small"
    @update:model-value="emits('update:modelValue', false)"
    @cancel="cancel"
    @confirm="submit"
  >
    <p v-if="isDealsCreated" class="text-body-2">
      There is already a <span class="cursor-pointer font-medium underline" @click="handleLinkClick">deal(s)</span> for
      this lead.<br />Confirm whether to add another deal or create a new lead.
    </p>
    <p v-else>This lead was created earlier. Do you want to create a new lead or add a deal to the existing one?</p>
  </UiPopup>
</template>

<script setup lang="ts">
import type { Lead } from '~/types'

const emits = defineEmits(['update:modelValue', 'input', 'createLead', 'createDeal'])

type Props = {
  modelValue: boolean
  lead: Lead
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const isDealsCreated = computed(() => {
  if (!props.lead.deals) return false

  return Boolean(props.lead.deals.length)
})

const handleLinkClick = () => {
  const isOnlyOneDeal = props.lead.deals?.length === 1

  if (isOnlyOneDeal) {
    navigateTo(`/deals/${props.lead.deals![0].id}`, {
      open: {
        target: '_blank',
      },
    })
  } else {
    const queryParams = props.lead.deals!.map(({ id }) => `deal_ids=${id}`).join('&')

    navigateTo(`/deals/all/current?${queryParams}`, {
      open: {
        target: '_blank',
      },
    })
  }
}

const submit = () => {
  emits('createDeal')
  emits('update:modelValue', false)
}

const cancel = () => {
  emits('createLead')
  emits('update:modelValue', false)
}
</script>

<style scoped></style>
